import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "general-guidance"
    }}>{`General guidance`}</h2>
    <p>{`Tooltips provide additional information upon hover or focus. The information should be contextual, useful, and nonessential information. Keep tooltips short.`}</p>
    <h2 {...{
      "id": "placement"
    }}>{`Placement`}</h2>
    <p>{`Icon tooltips and interactive tooltips may be positioned `}<strong parentName="p">{`top`}</strong>{`, `}<strong parentName="p">{`bottom`}</strong>{`, `}<strong parentName="p">{`left`}</strong>{`, or `}<strong parentName="p">{`right`}</strong>{` to the trigger item. The container of the tooltip text may be aligned to `}<strong parentName="p">{`start`}</strong>{`, `}<strong parentName="p">{`center`}</strong>{` or `}<strong parentName="p">{`end`}</strong>{`.`}</p>
    <p>{`Note that left and right positioning is not available for `}<a parentName="p" {...{
        "href": "#definition-tooltip"
      }}>{`definition tooltip`}</a>{`. This ensures the tooltip does not obstruct important information to the left or right of the trigger word.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.77173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsSAAALEgHS3X78AAABW0lEQVQoz2OoqalhAIGmpiaGrKwshvT0dDCdmprKUFpayvDy5UuGmzdvMty+fZvhzp07GBgkDgIwPhhUVlYyUAJu3LiB4GRnZ4Pp3NxcDqCrhFJSUgRzcnIEQDRQjP/Ro0dsV65cYb927Ro70AVsQM0gzA4SA7oOJMYCcyHYtYWFhWADy8rKGIHeZdm5cyeYX1VVxQS0jPn///9wy/fv38+IzL979y4j0CBGEBsWJGBQUFDAYGtrywRiOzk5Rfj5+a22trbWB/EdHBxMfHx8VgHFg0F8ExMTGV9f33lubm5FIL65uTmDi4sLwst5eXlg2sbGBkybmZnJeXh4qAEt4ALxHR0duYGGGgBpIRDf0tKSzdPTU9nKykoFxA8KCmIwNTVFdSEMAA1C4dvb26Pwga5E4ScmJuKOKWdnZzjt6urKCHOxnZ0dmA90JQPUhSAvMsK8CQweUHDBzQEAmPGVbEKR17sAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Tooltip positiion top.",
            "title": "Tooltip positiion top.",
            "src": "/static/9f68c2b453b1690b22bfa1447f7362b7/fb070/tooltip-usage-1-top.png",
            "srcSet": ["/static/9f68c2b453b1690b22bfa1447f7362b7/d6747/tooltip-usage-1-top.png 288w", "/static/9f68c2b453b1690b22bfa1447f7362b7/09548/tooltip-usage-1-top.png 576w", "/static/9f68c2b453b1690b22bfa1447f7362b7/fb070/tooltip-usage-1-top.png 1152w", "/static/9f68c2b453b1690b22bfa1447f7362b7/c3e47/tooltip-usage-1-top.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "34.51086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsSAAALEgHS3X78AAABCUlEQVQoz2O4c+cOAzbw//9/hoMHDzI8fvyYAaQGH759+zZYD4ymCoA7DMQwMzMDs9PS0rhSUlIEo6KihCZPnswJciUQs1y7do3t5s2bbF+/fmUF8hmBmOnWrVtsQL0gzApzGdhQENHX1wcW2L9/P+O6deuYQJri4uK4gAaLhYWFwQxmTE9PZwGKiYaHh/OD+Ddu3GAEGswIcx1K8M2cORNMKygoMIJoBwcHFx8fn+WWlpa2MDVKSkriXl5es9zc3PLRvQwPP5CpgYGBYHZ/fz9DUlISmG1ubs7k6urK6uzszASTMzAwYLC3t2cFGsoMEjt9+jQYE4wUWJjCANCL4FhHBlVVVVj1AgDsMJ2rCqOO+QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Tooltip position left and right.",
            "title": "Tooltip position left and right.",
            "src": "/static/4fce6ccf8989d468c1b2db71bd649e5c/fb070/tooltip-usage-1-left-right.png",
            "srcSet": ["/static/4fce6ccf8989d468c1b2db71bd649e5c/d6747/tooltip-usage-1-left-right.png 288w", "/static/4fce6ccf8989d468c1b2db71bd649e5c/09548/tooltip-usage-1-left-right.png 576w", "/static/4fce6ccf8989d468c1b2db71bd649e5c/fb070/tooltip-usage-1-left-right.png 1152w", "/static/4fce6ccf8989d468c1b2db71bd649e5c/c3e47/tooltip-usage-1-left-right.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.90760869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsSAAALEgHS3X78AAABT0lEQVQoz2NITExkAIH4+HiG5ORkhoyMDIa8vDyGpKQkhtzcXIb+/n6GDx8+MNy4cYPh9u3bWDEI3LlzB85miIuLY6AEgAyDg6ioKDAdERHBDXSlMNCVgllZWYIJCQlCQFqgubmZ58qVK+wnT55kv3//Ptu1a9fYjxw5wnH8+HF2oEHsQFexwAwFGxwbGws2MCYmhgnoUhaYRQsWLGAEepsF2SUrVqxg+v//P5wPNJTxyZMnDCgGIrsSBExMTCQ9PDyUzM3NOUB8e3t7LiDWtra2FgDxDQ0NWZydnWWMjY2lYXoiIyMRtgJdBqbd3NyYQDRQcaSPj896oAEGIL6jo6OZt7f3eqB4MIhvaWmpAJRfBFRfCI1MRqBaVBfCAFAjCt/JyQmFb2FhQXxMubu7g2lXV1cGeXl5BkVFRTC/uLiYwcjICMwGRgaKHuTwhAEAkqaIxDZ0wz4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Tooltip position bottom.",
            "title": "Tooltip position bottom.",
            "src": "/static/b6834e0306810937e8fefbe937a61926/fb070/tooltip-usage-1-bottom.png",
            "srcSet": ["/static/b6834e0306810937e8fefbe937a61926/d6747/tooltip-usage-1-bottom.png 288w", "/static/b6834e0306810937e8fefbe937a61926/09548/tooltip-usage-1-bottom.png 576w", "/static/b6834e0306810937e8fefbe937a61926/fb070/tooltip-usage-1-bottom.png 1152w", "/static/b6834e0306810937e8fefbe937a61926/c3e47/tooltip-usage-1-bottom.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <h2 {...{
      "id": "variants"
    }}>{`Variants`}</h2>
    <p>{`Tooltips provide additional, contextual information. Each variant achieves this for different design needs.`}</p>
    <h3 {...{
      "id": "icon-tooltip"
    }}>{`Icon tooltip`}</h3>
    <p>{`An icon tooltip is used to clarify the action or name of an interactive icon button.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "29.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsSAAALEgHS3X78AAAAfklEQVQY02NgwAJsbW0ZGhoawGw/Pz+GsLAwBh0dHTDfwcGBgWzQ2dmJwtfV1SXPIJALExMTwWxPT09RDw8PNVNTUyYQ39HRkTwDU1JSwGx3d/d0Ly+vhUADJUB8e3t7RrK9/P//fwaqABsbG7hhL168YPjz5w9cLjMzE69eAOfBIF531vVLAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example image of an icon tooltip.",
            "title": "Example image of an icon tooltip.",
            "src": "/static/228214539033de946f4cd8c4b12fcb5e/fb070/tooltip-usage-1.png",
            "srcSet": ["/static/228214539033de946f4cd8c4b12fcb5e/d6747/tooltip-usage-1.png 288w", "/static/228214539033de946f4cd8c4b12fcb5e/09548/tooltip-usage-1.png 576w", "/static/228214539033de946f4cd8c4b12fcb5e/fb070/tooltip-usage-1.png 1152w", "/static/228214539033de946f4cd8c4b12fcb5e/c3e47/tooltip-usage-1.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <h4 {...{
      "id": "guidance"
    }}>{`Guidance:`}</h4>
    <ul>
      <li parentName="ul">{`The tooltip content should only contain one or two words.`}</li>
    </ul>
    <h4 {...{
      "id": "behavior"
    }}>{`Behavior:`}</h4>
    <ul>
      <li parentName="ul">{`Icon tooltips appear on `}<inlineCode parentName="li">{`hover`}</inlineCode>{` and `}<inlineCode parentName="li">{`focus`}</inlineCode>{`.`}</li>
    </ul>
    <h3 {...{
      "id": "definition-tooltip"
    }}>{`Definition tooltip`}</h3>
    <p>{`The definition tooltip provides additional help or defines an item or term. It may be used on the label of a UI element, or on a word embedded in a paragraph.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.776611694152926%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsSAAALEgHS3X78AAAA+UlEQVQoz6VSO6qDQBR1Ma4jr1EsXIUL0KeQzsKIO7FzA/F11q5AdOKzsRGsFC38ncQJIxomEMiFw7n3cjlzPyMsy4LVVv4GTENgzrfGNIS9+jRNGMeRYnpgGIanv88//Nf40CEL5nlGURRIkgSE3HDL/2mcpinlPM83JoRQrLVZlqHve75gWZaoqgrX8A+/1hmXiwvXdeE4DjzPg67rCMMQXdehrmuKtm0PuzyM3DQNFQ6CAD+nE1RVhSRJkGUZiqJAFEX4vk/r11G5O9wHjOM4hmVZsG2bsmEYME0TmqYhiqJNkHtl3rf59KKvYluHvMJ97t2f4z1+BzA9npWXW36eAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Definition tooltip used to define a word within a paragraph.",
            "title": "Definition tooltip used to define a word within a paragraph.",
            "src": "/static/9ae8f0634147b1156075e4c208dd00aa/fb070/definition_tooltip_image.png",
            "srcSet": ["/static/9ae8f0634147b1156075e4c208dd00aa/d6747/definition_tooltip_image.png 288w", "/static/9ae8f0634147b1156075e4c208dd00aa/09548/definition_tooltip_image.png 576w", "/static/9ae8f0634147b1156075e4c208dd00aa/fb070/definition_tooltip_image.png 1152w", "/static/9ae8f0634147b1156075e4c208dd00aa/f7252/definition_tooltip_image.png 1334w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <h4 {...{
      "id": "guidance-1"
    }}>{`Guidance:`}</h4>
    <ul>
      <li parentName="ul">{`Should contain brief, read-only text`}</li>
      <li parentName="ul">{`Use on proper nouns, technical terms, or acronyms with two letters or more`}</li>
      <li parentName="ul">{`Do not use a definition tooltip on words with fewer than two letters`}</li>
    </ul>
    <h4 {...{
      "id": "behavior-1"
    }}>{`Behavior:`}</h4>
    <ul>
      <li parentName="ul">{`Definition tooltips appear on `}<inlineCode parentName="li">{`hover`}</inlineCode>{` and `}<inlineCode parentName="li">{`focus`}</inlineCode></li>
    </ul>
    <h3 {...{
      "id": "interactive-tooltips"
    }}>{`Interactive tooltips`}</h3>
    <p>{`Interactive tooltips may contain rich text and other interactive elements like buttons or links. In general, hiding interactive content in a tooltip is discouraged. Interactive tooltips are best used for onboarding experiences and product tours.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.16304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAAB80lEQVQoz2NgAAI5OTkxJSUlFQUFBRV1dXVVFRUVVWlpaTiWlJRUkZCQUIHRyBgqpgakJRlgQFFRUUdDQ8MOaJgekNZTVlbW5eHhAWN+fn49KSkpAxAG6gFjoAFwGoj1gWxjIFaEGygvL68N1KAGYu/fv5//8OHDfGfOnOE7e/Ys/4kTJ3gYiARwRyooKukBvaYOYv///18EiEWhtBgQ80PFWS5dusS2efNmsB5TU1OwAcgYDoT4uRQlJaV03Nzc+kNCQlZ5e3svd3FxWeHq6rociNcEBATMAbrc7N27d6Ig9bm5uQzZ2dkMQO+iYDjQNbEP19RQzwgODvmemZn5PzQ09H9AQOD/wKCQ/96+/v+tbWzfxURHisHU3717F7/fzR0C1liYm2z38vK6ExwU9DcoKOh7ZHjw92Bv+29eTqb/nGzNbmroW4G97unpyQii7927h9tAEXEZQ2UlBTc7B6cfzm4+/50cbP6buKf/l489+F88+vh/Na/mt/G6DEIgtdbW1owgF+J1pZiYqJqcnKyeg5Nbqb2LV4u9nXWjoUtyo3zMvgaxsL2tql5tRbGaDBwgtba2tkRFt76EhKQWIXUVEkSmH2AMSQMNlXj++z+DrYsfs6OdJZOmZy0TV8F/Joa0/8xiwduZimUham1sbPCaBQCCkaFEvgg0PQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of an interactive tooltip in a product walk through.",
            "title": "Example of an interactive tooltip in a product walk through.",
            "src": "/static/22cb033fd87fa24bd50097ba678ef3ff/fb070/tooltip-usage-3-v2.png",
            "srcSet": ["/static/22cb033fd87fa24bd50097ba678ef3ff/d6747/tooltip-usage-3-v2.png 288w", "/static/22cb033fd87fa24bd50097ba678ef3ff/09548/tooltip-usage-3-v2.png 576w", "/static/22cb033fd87fa24bd50097ba678ef3ff/fb070/tooltip-usage-3-v2.png 1152w", "/static/22cb033fd87fa24bd50097ba678ef3ff/c3e47/tooltip-usage-3-v2.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <h4 {...{
      "id": "guidance-2"
    }}>{`Guidance:`}</h4>
    <ul>
      <li parentName="ul">{`If a user may need to visit an external resource, like while using a form, include a link in your interactive tooltip`}</li>
      <li parentName="ul">{`Don’t use without a label. Consider the context a user needs before clicking a link`}</li>
    </ul>
    <h4 {...{
      "id": "behavior-2"
    }}>{`Behavior:`}</h4>
    <ul>
      <li parentName="ul">{`Interactive tooltips appear when the user clicks on an info icon`}</li>
      <li parentName="ul">{`They persistent until intentionally dismissed by clicking outside of the tooltip`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      